import React, { useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionHero.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(resp => {
        if (resp.data.data) {
          sdk.currentUser
            .updateProfile({
              publicData: {
                lastActive: new Date().getTime(),
              },
            })
            .catch(e => console.log(e));
        }
      })
      .catch(e => console.log(e));
  }, []);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <a href={'https://thenold.com'} className={css.heroButton1}>
            THENOLD.COM
          </a>
          {/* <a
            href={
              'https://thenold.com/shop?availability=hide_sold&category=&condition=sample-sale&department=&preloved=false&priceRange=0,10000&shipsTo=&sortBy=newest&text='
            }
            className={css.heroButton2}
          >
            КЪМ THENOLD.COM
          </a> */}
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
